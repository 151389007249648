import { useState } from "react";

import EditorDashboard from "./EditorDashboard";
import EditorLogin from "./EditorLogin";

function Editor(props) {

    let [authenticationToken, setAuthenticationToken] = useState(undefined);

    return (
        <div style={{width: '100vw'}}>
            <div className="center">
                <EditorLogin setAuthenticationToken={setAuthenticationToken} show={!authenticationToken}/>
            </div>

            <EditorDashboard authenticationToken={authenticationToken} show={authenticationToken}/>
        </div>
      );
}

export default Editor;

