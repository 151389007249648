import React, { useState } from 'react';

import { AfficheImageMapper } from "../../Mapper/AfficheImageMapper";

function Posters(props) {
    
    let [search, setSearch] = useState("");

    if(!props.show || !props.posters || !Array.isArray(props.posters)) return null;

    return (
        <div className="wrap horizontalcenter" style={{marginBottom: 0, marginTop: 0}}>
            <label className="sr-only" htmlFor="inputEmail">Recherche</label>
            <input value={search} onChange={(e) => setSearch(e.target.value)} style={{marginLeft: 100, marginRight: 100, marginBlock: 20}} className="form-control" type="email" name="mail" id="inputEmail" placeholder="Recherche" required="required" autofocus="autofocus" />

            {
                props.posters.filter(p => p.posterTitle.toLowerCase().includes(search.toLowerCase())).map((poster) => (
                    <div onClick={() => props.setSelectedPoster(poster)}>
                        <div key={poster.toString()} className="poster">
                            <img className="poster" style={{margin: 0}} src={poster.isLocal ? AfficheImageMapper[poster.code] : {uri: poster.url}} alt={poster.posterTitle}/>
                        </div>
                        <p style={{maxWidth: 130}}>{poster.posterTitle}</p>
                    </div>

                ))
            }
        </div>
      );
}

export default Posters;

