
const apiUrl = "https://talkandpoke.com/talkandpokeapi/";

export default async function fetchTalkAndPokeApi (endpoint, authenticationToken, params, requestMethod, headers = {}, body = undefined) {

    let url = apiUrl + endpoint;

    if(params) {
        for(let i = 0; i < params.length; i++) {
            if(i === 0)
                url = url + "?" + encodeURIComponent(params[i][0]) + '=' + encodeURIComponent(params[i][1]);
            else
                url = url + "&" + encodeURIComponent(params[i][0]) + '=' + encodeURIComponent(params[i][1]);
        }
    }

    if(!headers) headers = {};

    Object.assign(headers, {'Application-Identification': "555937ab-3cf0-11ee-9fc5-e0071bf394b0"});
    Object.assign(headers, {'Authorization': "Bearer " + authenticationToken});

    let options = {
        method: requestMethod,
        headers: headers,
        body: body
    }

    const resp = await fetch(url, options)
    .then((response) => {
        //console.log(response);
        return response.json()})
        .catch((error) => {
            logerror(url, error, requestMethod);
        })
    .then((data) => {
        log(url, data, requestMethod);
        return data;
    })
    .catch((error) => {
        logerror(url, error, requestMethod);
    });
    
    return await resp;
};

function log(url, data, requestMethod) {
    console.log("=================================================================");
    console.log("Request : " + JSON.stringify(url));
    console.log("Method : " + JSON.stringify(requestMethod));
    console.log("Response : " + JSON.stringify(data).substring(0, 500));
    console.log("=================================================================");
}

function logerror(url, data, requestMethod) {
    console.error("=================================================================");
    console.error("Request : " + JSON.stringify(url));
    console.error("Method : " + JSON.stringify(requestMethod));
    console.error("Response error : " + JSON.stringify(data).substring(0, 500));
    console.error("=================================================================");
}