import React from 'react';

import { AfficheImageMapper } from "../../Mapper/AfficheImageMapper";
import BackArrow from './BackArrow';

function PosterDetails(props) {
    
    if(!props.show) return null;

    return (
        <div className="wrap horizontalcenter" style={{marginBottom: 0, marginTop: 0}}>
            <div>
                <BackArrow backCommand={() => props.setSelectedPoster(undefined)}/>

                <div key={props.poster.toString()} className="poster">
                    <img className="poster" style={{margin: 0}} src={props.poster.isLocal ? AfficheImageMapper[props.poster.code] : {uri: props.poster.url}} alt={props.poster.posterTitle}/>
                </div>
                <p style={{maxWidth: 130}}>{props.poster.posterTitle}</p>
            </div>
        </div>
      );
}

export default PosterDetails;

