import { useState } from "react";

import { getUserAuthenticationToken } from "../../service/AuthenticationService";

function EditorLogin(props) {

    let [response, setErreur] = useState("");
    let [mail, setMail] = useState("");
    let [password, setPassword] = useState("");

    if(!props.show) return null;

    async function getToken() {

        let token = await getUserAuthenticationToken(mail, password);

        if(token && token.authenticationToken) {

            try {        
                let user = JSON.parse(atob(token.authenticationToken.split('.')[1]));

                if(user && user.roles.includes("52b77d4a-4004-11ee-918f-e0071bf394b0")) {
                    props.setAuthenticationToken(token.authenticationToken);
                } else {
                    setErreur("Erreur dans vos identifiants");
                }
        
            } catch(error) {
                setErreur("Erreur dans vos identifiants");
            }
        } else {
            setErreur("Erreur dans vos identifiants");
        }
    }

    return (
        <div className="center">
            <div>
                <h1 className="h3 mb-3 font-weight-normal">Connexion</h1>
                <p style={{maxWidth: 300}}>{response}</p>
                <label className="sr-only" htmlFor="inputEmail">Email address</label>
                <input value={mail} onChange={(e) => setMail(e.target.value)} className="form-control" type="email" name="mail" id="inputEmail" placeholder="Email address" required="required" autofocus="autofocus" />
                <label className="sr-only" htmlFor="inputPassword">Password</label>
                <input value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" type="password" name="password" id="inputPassword" placeholder="Password" required="required" />
                <button onClick={() => getToken()} className="btn btn-lg btn-primary btn-block buttonClass" type="submit">Se connecter</button>
            </div>
        </div>
      );
}

export default EditorLogin;

