import { useState } from 'react';

import logo from '../resources/logo.png';
import Menu from './Menu';

function Layout(props) {

    let [show, setShow] = useState(false);
    let [showConditionLinks, setShowConditionLinks] = useState(false);

    function ConditionLinks() {

        if(!showConditionLinks) return null;

        return (
            <div>
                <div onClick={() => setShowConditionLinks(false)} style={{height: '100vh', width: '100vw', position: 'absolute', top: 80, left: 0, zIndex: 1}}></div>

                <div style={{position: 'absolute', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: 19, padding: 10, zIndex: 1}}>
                    <a href="/conditions-generales-utilisation">
                        <h3 style={{color: 'orange'}}>Conditions générales d'utilisation</h3>
                    </a>
                    <a href="/mentions-legales">
                        <h3 style={{color: 'orange'}}>Mentions légales</h3>
                    </a>
                    <a href="/charte-de-donnees">
                        <h3 style={{color: 'orange'}}>Charte de données</h3>
                    </a>
                </div>
            </div>
            
        )
    }

    return (
        <html>
            {/* HEADER */}
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" />
            </head>

            {/* TOP BARRE */}
            <body id="container">
                <Menu show={show} setShow={setShow}/>

                <div id="header">
                    <a href="/" style={{display: 'flex'}}>
                        <img className="logo" src={logo} alt="png" />
                        <h1 style={{color: 'white', margin: '8px'}}>Talk&amp;Poke</h1>
                    </a>
                    <div className="headspace" />
                    <h1 className="menubutton" onClick={() => setShow(true)}>☰</h1>
                    <h2 className="navitem" style={{cursor: 'pointer'}}>
                        <div onClick={() => setShowConditionLinks(b => !b)} style={{flexDirection: 'row', display: 'flex'}}>
                            <h2 style={{color: 'white', margin: '8px'}}>Conditions</h2>
                            <p style={{color: 'white', marginTop: 10, marginLeft: -5}}>﹀</p>
                        </div>
                        <ConditionLinks/>
                    </h2>
                    <h2 className="navitem">
                        <a href="/creator?description=true">
                            <h2 style={{color: 'white', margin: '8px'}}>Créateur</h2>
                        </a>
                    </h2>
                    <h2 className="navitem">
                        <a href="/support">
                            <h2 style={{color: 'white', margin: '8px'}}>Support</h2>
                        </a>
                    </h2>
                    <h2 className="navitem">
                        <a href="/about">
                            <h2 style={{color: 'white', margin: '8px'}}>A propos de nous</h2>
                        </a>
                    </h2>
                </div>

                {/* CONTENT */}
                {props.children}

                {/* FOOTER */}
                <div id="footer">
                    <p>Copyright © 2023 Talk&amp;Poke</p>
                </div>
            </body>
        </html>
      );
}

export default Layout;

