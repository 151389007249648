import logo from '../resources/logo.png';

function Menu(props) {

    if(!props.show) return null;

    return (
        <div id="menu">
            <section id="menuitems">
                <div>
                    <h1 style={{color: "white", marginTop: '10px'}} className="menubutton" onClick={() => props.setShow(false)}>☰</h1>
                </div>
                <a href="/">
                    <div style={{position: 'absolute', marginTop: 15}}>
                        <img className="logo" src={logo} alt="png" style={{height: '40px', width: '40px'}} />
                    </div>
                    <h3 style={{color: 'white'}}><p style={{marginLeft: 30, height: 20}}>Talk&Poke</p></h3>
                </a>
                <a href="/creator?description=true">
                    <h3 style={{color: 'white'}}>Créateur</h3>
                </a>
                <a href="/support">
                    <h3 style={{color: 'white'}}>Support</h3>
                </a>
                <a href="/about">
                    <h3 style={{color: 'white'}}>A propos de nous</h3>
                </a>
                <a href="/conditions-generales-utilisation">
                    <h3 style={{color: 'white'}}>Conditions générales d'utilisation</h3>
                </a>
                <a href="/mentions-legales">
                    <h3 style={{color: 'white'}}>Mentions légales</h3>
                </a>
                <a href="/charte-de-donnees">
                    <h3 style={{color: 'white'}}>Charte de données</h3>
                </a>
            </section>
        </div>
      );
}

export default Menu;

