import Automne from '../resources/Affiches/Automne.jpeg';
import Perroquet from '../resources/Affiches/Perroquet.jpeg';
import Saisons from '../resources/Affiches/Saisons.jpeg';
import PatesBolognaise from '../resources/Affiches/PatesBolognaise.jpeg';
import Pancake from '../resources/Affiches/Pancake.jpeg';
import Chats from '../resources/Affiches/Chats.jpeg';
import Vacances from '../resources/Affiches/Vacances.jpeg';
import FeteForaine from '../resources/Affiches/FeteForaine.jpeg';
import TRex from '../resources/Affiches/TRex.jpeg';
import PatesPesto from '../resources/Affiches/PatesPesto.jpeg';
import Rio from '../resources/Affiches/Rio.jpeg';
import Piment from '../resources/Affiches/Piment.jpeg';
import Tigres from '../resources/Affiches/Tigres.jpeg';
import Lasagne from '../resources/Affiches/Lasagne.jpeg';
import TourEiffel from '../resources/Affiches/TourEiffel.jpeg';
import Litchi from '../resources/Affiches/Litchi.jpeg';
import Ski from '../resources/Affiches/Ski.jpeg';
import Crepes from '../resources/Affiches/Crepes.jpeg';
import Fruits from '../resources/Affiches/Fruits.jpeg';
import Gouter from '../resources/Affiches/Gouter.jpeg';
import Diplodocus from '../resources/Affiches/Diplodocus.jpeg';
import Kiwi from '../resources/Affiches/Kiwi.jpeg';
import Leopard from '../resources/Affiches/Leopard.jpeg';
import Velo from '../resources/Affiches/Velo.jpeg';
import Dinosaures from '../resources/Affiches/Dinosaures.jpeg';
import Banane from '../resources/Affiches/Banane.jpeg';
import SignesAstrologique from '../resources/Affiches/SignesAstrologique.jpeg';
import Ete from '../resources/Affiches/Ete.jpeg';
import Hibou from '../resources/Affiches/Hibou.jpeg';
import Sports from '../resources/Affiches/Sports.jpeg';
import Elements from '../resources/Affiches/Elements.jpeg';
import Gemeaux from '../resources/Affiches/Gemeaux.jpeg';
import Boxe from '../resources/Affiches/Boxe.jpeg';
import Colisee from '../resources/Affiches/Colisee.jpeg';
import Cannelle from '../resources/Affiches/Cannelle.jpeg';
import TirALArc from '../resources/Affiches/TirALArc.jpeg';
import Belier from '../resources/Affiches/Belier.jpeg';
import Figue from '../resources/Affiches/Figue.jpeg';
import Piano from '../resources/Affiches/Piano.jpeg';
import Plage from '../resources/Affiches/Plage.jpeg';
import LionAstro from '../resources/Affiches/LionAstro.jpeg';
import MonumentsHistorique from '../resources/Affiches/MonumentsHistorique.jpeg';
import Sagittaire from '../resources/Affiches/Sagittaire.jpeg';
import Ananas from '../resources/Affiches/Ananas.jpeg';
import Hiver from '../resources/Affiches/Hiver.jpeg';
import Orange from '../resources/Affiches/Orange.jpeg';
import Feu from '../resources/Affiches/Feu.jpeg';
import Canides from '../resources/Affiches/Canides.jpeg';
import Vierge from '../resources/Affiches/Vierge.jpeg';
import Ville from '../resources/Affiches/Ville.jpeg';
import Cartes from '../resources/Affiches/Cartes.jpeg';
import Tokyo from '../resources/Affiches/Tokyo.jpeg';
import Scorpion from '../resources/Affiches/Scorpion.jpeg';
import Paris from '../resources/Affiches/Paris.jpeg';
import PandaRoux from '../resources/Affiches/PandaRoux.jpeg';
import Cancer from '../resources/Affiches/Cancer.jpeg';
import Montagne from '../resources/Affiches/Montagne.jpeg';
import Londres from '../resources/Affiches/Londres.jpeg';
import Epices from '../resources/Affiches/Epices.jpeg';
import Athletisme from '../resources/Affiches/Athletisme.jpeg';
import Tennis from '../resources/Affiches/Tennis.jpeg';
import Eau from '../resources/Affiches/Eau.jpeg';
import NewYork from '../resources/Affiches/NewYork.jpeg';
import Poisson from '../resources/Affiches/Poisson.jpeg';
import Fete from '../resources/Affiches/Fete.jpeg';
import Renard from '../resources/Affiches/Renard.jpeg';
import Football from '../resources/Affiches/Football.jpeg';
import SagradaFamilia from '../resources/Affiches/SagradaFamilia.jpeg';
import Churros from '../resources/Affiches/Churros.jpeg';
import Verseau from '../resources/Affiches/Verseau.jpeg';
import Chien from '../resources/Affiches/Chien.jpeg';
import Voley from '../resources/Affiches/Voley.jpeg';
import Sydney from '../resources/Affiches/Sydney.jpeg';
import Felin from '../resources/Affiches/Felin.jpeg';
import Balance from '../resources/Affiches/Balance.jpeg';
import Snowboard from '../resources/Affiches/Snowboard.jpeg';
import Dormir from '../resources/Affiches/Dormir.jpeg';
import Printemps from '../resources/Affiches/Printemps.jpeg';
import Fraise from '../resources/Affiches/Fraise.jpeg';
import Taureau from '../resources/Affiches/Taureau.jpeg';
import Natation from '../resources/Affiches/Natation.jpeg';
import Lion from '../resources/Affiches/Lion.jpeg';
import Capricorne from '../resources/Affiches/Capricorne.jpeg';
import Loup from '../resources/Affiches/Loup.jpeg';
import Boisson from '../resources/Affiches/Boisson.jpeg';
import Oiseau from '../resources/Affiches/Oiseau.jpeg';
import Triceratops from '../resources/Affiches/Triceratops.jpeg';
import Poker from '../resources/Affiches/Poker.jpeg';
import Cafe from '../resources/Affiches/Cafe.jpeg';
import Curry from '../resources/Affiches/Curry.jpeg';
import TourDePise from '../resources/Affiches/TourDePise.jpeg';
import Poivre from '../resources/Affiches/Poivre.jpeg';
import Peche from '../resources/Affiches/Peche.jpeg';
import Caire from '../resources/Affiches/Caire.jpeg';
import Escalade from '../resources/Affiches/Escalade.jpeg';
import Fennec from '../resources/Affiches/Fennec.jpeg';
import InstrumentsDeMusique from '../resources/Affiches/InstrumentsDeMusique.jpeg';
import Pates from '../resources/Affiches/Pates.jpeg';
import VingtEtUn from '../resources/Affiches/VingtEtUn.jpeg';
import PatesCarbonara from '../resources/Affiches/PatesCarbonara.jpeg';
import Fin from '../resources/Affiches/Fin.jpeg';
import Alcool from '../resources/Affiches/Alcool.jpeg';
import Arcade from '../resources/Affiches/Arcades.jpeg';
import Avion from '../resources/Affiches/Avion.jpeg';
import Egypt from '../resources/Affiches/Egypt.jpeg';
import Hawai from '../resources/Affiches/Hawai.jpeg';
import Manga from '../resources/Affiches/Manga.jpeg';
import Moto from '../resources/Affiches/Moto.jpeg';
import Pays from '../resources/Affiches/Pays.jpeg';
import Popcorn from '../resources/Affiches/PopCorn.jpeg';
import Sel from '../resources/Affiches/Sel.jpeg';
import Vehicule from '../resources/Affiches/Vehicule.jpeg';
import Voiture from '../resources/Affiches/Voiture.jpeg';
import Livre from '../resources/Affiches/Livre.jpeg';
import Girafe from '../resources/Affiches/Girafe.jpeg';
import Cigarette from '../resources/Affiches/Cigarette.jpeg';
import BoiteDeNuit from '../resources/Affiches/BoiteDeNuit.jpeg';
import Hamburger from '../resources/Affiches/Hamburger.jpeg';
import AnimalSauvage from '../resources/Affiches/AnimalSauvage.jpeg';
import Tatouage from '../resources/Affiches/Tatouage.jpeg';
import ReseauSocial from '../resources/Affiches/ReseauSocial.jpeg';
import Surf from '../resources/Affiches/Surf.jpeg';
import Bijou from '../resources/Affiches/Bijou.jpeg';
import Skate from '../resources/Affiches/Skate.jpeg';
import Carnaval from '../resources/Affiches/Carnaval.jpeg';
import Champagne from '../resources/Affiches/Champagne.jpeg';
import PoissonSauvage from '../resources/Affiches/PoissonSauvage.jpeg';
import Festival from '../resources/Affiches/Festival.jpeg';
import Dauphin from '../resources/Affiches/Dauphin.jpeg';
import LosAngeles from '../resources/Affiches/LosAngeles.jpeg';
import Cerise from '../resources/Affiches/Cerise.jpeg';
import Camping from '../resources/Affiches/Camping.jpeg';
import Piercing from '../resources/Affiches/Piercing.jpeg';
import Concert from '../resources/Affiches/Concert.jpeg';
import Chocolat from '../resources/Affiches/Chocolat.jpeg';
import Comics from '../resources/Affiches/Comics.jpeg';
import Sucre from '../resources/Affiches/Sucre.jpeg';
import Armee from '../resources/Affiches/Armee.jpeg';
import Lumiere from '../resources/Affiches/Lumiere.jpeg';
import Game from '../resources/Affiches/Game.jpeg';
import SkinCare from '../resources/Affiches/SkinCare.jpeg';
import Lune from '../resources/Affiches/Lune.jpeg';
import Maquillage from '../resources/Affiches/Maquillage.jpeg';
import Pyramide from '../resources/Affiches/Pyramide.jpeg';
import Mars from '../resources/Affiches/Mars.jpeg';
import Pizza from '../resources/Affiches/Pizza.jpeg';
import Vegetarien from '../resources/Affiches/Vegetarien.jpeg';
import Travail from '../resources/Affiches/Travail.jpeg';
import Sushi from '../resources/Affiches/Sushi.jpeg';
import Camembert from '../resources/Affiches/Camembert.jpeg';
import Voyager from '../resources/Affiches/Voyager.jpeg';
import Myrtille from '../resources/Affiches/Myrtille.jpeg';
import OliveNoir from '../resources/Affiches/OliveNoir.jpeg';
import Neige from '../resources/Affiches/Neige.jpeg';
import Lys from '../resources/Affiches/Lys.jpeg';
import ChocolatBlanc from '../resources/Affiches/ChocolatBlanc.jpeg';
import Flute from '../resources/Affiches/Flute.jpeg';
import Lire from '../resources/Affiches/Lire.jpeg';
import Japon from '../resources/Affiches/Japon.jpeg';
import Russie from '../resources/Affiches/Russie.jpeg';
import Ukraine from '../resources/Affiches/Ukraine.jpeg';
import Accordeon from '../resources/Affiches/Accordeon.jpeg';
import Batterie from '../resources/Affiches/Batterie.jpeg';
import ChocolatNoir from '../resources/Affiches/ChocolatNoir.jpeg';
import Dubai from '../resources/Affiches/Dubai.jpeg';
import Ami from '../resources/Affiches/Ami.jpeg';
import Shopping from '../resources/Affiches/Shopping.jpeg';
import The from '../resources/Affiches/The.jpeg';
import Cuisiner from '../resources/Affiches/Cuisiner.jpeg';
import Koala from '../resources/Affiches/Koala.jpeg';
import Biere from '../resources/Affiches/Biere.jpeg';
import Saturne from '../resources/Affiches/Saturne.jpeg';
import VinBlanc from '../resources/Affiches/VinBlanc.jpeg';
import Barcelone from '../resources/Affiches/Barcelone.jpeg';
import Rose from '../resources/Affiches/Rose.jpeg';
import Barbecue from '../resources/Affiches/Barbecue.jpeg';
import Paella from '../resources/Affiches/Paella.jpeg';
import Oeuf from '../resources/Affiches/Oeuf.jpeg';
import Moule from '../resources/Affiches/Moule.jpeg';
import Saumon from '../resources/Affiches/Saumon.jpeg';
import Citation from '../resources/Affiches/Citation.png'
import VictorHugoQuote from '../resources/Affiches/VictorHugoQuote.jpeg'
import SteveJobsQuote from '../resources/Affiches/SteveJobsQuote.jpeg'
import NelsonMandelaQuote from '../resources/Affiches/NelsonMandelaQuote.jpeg'
import MichelleObamaQuote from '../resources/Affiches/MichelleObamaQuote.jpeg'
import MarcTwainQuote from '../resources/Affiches/MarcTwainQuote.jpeg'
import JimRohnQuote from '../resources/Affiches/JimRohnQuote.jpeg'
import Violon from '../resources/Affiches/Violon.jpeg'
import RoadTrip from '../resources/Affiches/RoadTrips.jpeg'
import GuitareAcoustique from '../resources/Affiches/GuitareAcoustique.jpeg'
import GuitareElectrique from '../resources/Affiches/GuitareElectrique.jpeg'
import Chanter from '../resources/Affiches/Chanter.jpeg'
import Saxophone from '../resources/Affiches/Saxophone.jpeg'
import Trompette from '../resources/Affiches/Trompette.jpeg'
import AmeliaEarhartQuote from '../resources/Affiches/AmeliaEarhartQuote.jpeg'
import Danser from '../resources/Affiches/Danser.jpeg'
import Espace from '../resources/Affiches/Espace.jpeg'
import France from '../resources/Affiches/France.jpeg'
import Allemagne from '../resources/Affiches/Allemagne.jpeg'
import EtatsUnis from '../resources/Affiches/EtatsUnis.jpeg'
import Chine from '../resources/Affiches/Chine.jpeg'
import Italie from '../resources/Affiches/Italie.jpeg'
import Belgique from '../resources/Affiches/Belgique.jpeg'
import Espagne from '../resources/Affiches/Espagne.jpeg'
import Etoile from '../resources/Affiches/Etoile.jpeg'
import Ecrire from '../resources/Affiches/Ecrire.jpeg'
import Phoque from '../resources/Affiches/Phoque.jpeg'
import Fondue from '../resources/Affiches/Fondue.jpeg'
import OliveVerte from '../resources/Affiches/OliveVerte.jpeg'
import VinRouge from '../resources/Affiches/VinRouge.jpeg'
import ChocolatAuLait from '../resources/Affiches/ChocolatAuLAit.jpeg'
import FeuxArtifice from '../resources/Affiches/FeuxArtifice.jpeg'
import Bar from '../resources/Affiches/Bar.jpeg'
import Fajitas from '../resources/Affiches/Fajitas.jpeg'
import Bain from '../resources/Affiches/Bain.jpeg'
import Plante from '../resources/Affiches/Plante.jpeg'
import SalleGlobal from '../resources/Affiches/SalleGlobal.jpeg'
import Adidas from  "../resources/Affiches/Adidas.jpeg";
import Disney from  "../resources/Affiches/Disney.jpeg";
import Apple from  "../resources/Affiches/Apple.jpeg";
import Audi from  "../resources/Affiches/Audi.jpeg";
import AmazonPlus from  "../resources/Affiches/AmazonPlus.jpeg";
import CocaCola from  "../resources/Affiches/CocaCola.jpeg";
import Fortnite from  "../resources/Affiches/Fortnite.jpeg";
import Google from  "../resources/Affiches/Google.jpeg";
import Ferrari from  "../resources/Affiches/Ferrari.jpeg";
import Hobbit from  "../resources/Affiches/Hobbit.jpeg";
import HarryPotter from  "../resources/Affiches/HarryPotter.jpeg";
import Instagram from  "../resources/Affiches/Instagram.jpeg";
import JeuMobile from  "../resources/Affiches/JeuMobile.jpeg";
import MacDonalds from  "../resources/Affiches/MacDonalds.jpeg";
import Maternite from  "../resources/Affiches/Maternite.jpeg";
import Monster from  "../resources/Affiches/Monster.jpeg";
import Netflix from  "../resources/Affiches/Netflix.jpeg";
import Nike from  "../resources/Affiches/Nike.jpeg";
import Omega from  "../resources/Affiches/Omega.jpeg";
import Pepsi from  "../resources/Affiches/Pepsi.jpeg";
import PlayStation from  "../resources/Affiches/PlayStation.jpeg";
import Porsche from  "../resources/Affiches/Porsche.jpeg";
import RealiteVirtuel from  "../resources/Affiches/RealiteVirtuelle.jpeg";
import RedBull from  "../resources/Affiches/RedBull.jpeg";
import RetroGaming from  "../resources/Affiches/RetroGaming.jpeg";
import Rolex from  "../resources/Affiches/Rolex.jpeg";
import Samsung from  "../resources/Affiches/Samsung.jpeg";
import Spotify from  "../resources/Affiches/Spotify.jpeg";
import Starbuck from  "../resources/Affiches/Starbuck.jpeg";
import StarWars from  "../resources/Affiches/StarWars.jpeg";
import Tesla from  "../resources/Affiches/Tesla.jpeg";
import Twitter from  "../resources/Affiches/Twitter.jpeg";
import XBox from  "../resources/Affiches/XBox.jpeg";
import Youtube from  "../resources/Affiches/Youtube.jpeg";
import Cinema from "../resources/Affiches/Cinema.jpeg";
import ObserverFaune from "../resources/Affiches/ObserverFaune.jpeg";
import LeverSoleil from "../resources/Affiches/CoucherSoleil.jpeg";
import Informatique from "../resources/Affiches/Informatique.jpeg";
import Photographie from "../resources/Affiches/Photographie.jpeg";
import Peinture from "../resources/Affiches/Peinture.jpeg";
import Donuts from "../resources/Affiches/Donuts.jpeg";
import Famille from "../resources/Affiches/Famille.jpeg";
import PechePoisson from "../resources/Affiches/PechePoisson.jpeg";
import Entrepreneur from "../resources/Affiches/Entrepreneur.jpeg";
import Echecs from "../resources/Affiches/Echecs.jpeg";
import Conference from "../resources/Affiches/Conference.jpeg";
import StreetFood from "../resources/Affiches/StreetFood.jpeg";
import Jardinage from "../resources/Affiches/Jardiner.jpeg";
import EscapeGame from "../resources/Affiches/EscapeGame.jpeg";
import MurderParty from "../resources/Affiches/MurderParty.jpeg"; 
import Bowling from "../resources/Affiches/Bowling.jpeg";
import Benevolat from "../resources/Affiches/Benevolat.jpeg";
import BijouxFaitMain from "../resources/Affiches/BijouxFaitMain.jpeg";
import Canoe from "../resources/Affiches/Canoe.jpeg";
import Tricot from "../resources/Affiches/Tricot.jpeg";
import PiqueNique from "../resources/Affiches/PiqueNique.jpeg";
import Origami from "../resources/Affiches/Origami.jpeg";
import ParcNaturel from "../resources/Affiches/ParcNaturel.jpeg";
import AnniversaireSurprise from "../resources/Affiches/AnniversaireSurprise.jpeg";
import Sculpture from "../resources/Affiches/Sculpture.jpeg";
import RealiteVirtuelle from "../resources/Affiches/RealiteVirtuelle.jpeg";
import Dessin from "../resources/Affiches/Dessin.jpeg";
import BaladeForet from "../resources/Affiches/BaladeForet.jpeg";
import Flechette from "../resources/Affiches/Flechette.jpeg";
import Exposition from "../resources/Affiches/Exposition.jpeg";
import Broderie from "../resources/Affiches/Broderie.jpeg";
import Scrapbooking from "../resources/Affiches/Scrapbooking.jpeg";
import SerieFilm from "../resources/Affiches/SerieFilm.jpeg";
import JardinBotanique from "../resources/Affiches/JardinBotanique.jpeg";
import Poterie from "../resources/Affiches/Poterie.jpeg";
import Paintball from "../resources/Affiches/Paintball.jpeg";
import Chateau from "../resources/Affiches/Chateau.jpeg";
import Calligraphie from "../resources/Affiches/Calligraphie.jpeg";
import Patisser from "../resources/Affiches/Patisser.jpeg";
import ParcAttraction from "../resources/Affiches/ParcAttraction.jpeg";
import Discotheque from "../resources/Affiches/Discotheque.jpeg";
import Bateau from "../resources/Affiches/Bateau.jpeg";
import Musique from "../resources/Affiches/Musique.jpeg";
import JeuSociete from "../resources/Affiches/JeuSociete.jpeg";
import JeuAlcool from "../resources/Affiches/JeuAlcool.jpeg";
import MatchSport from "../resources/Affiches/MatchSport.jpeg";
import DinerRomantique from "../resources/Affiches/DinerRomantique.jpeg";
import Cocktail from "../resources/Affiches/Cocktail.jpeg";
import FamilleAmi from "../resources/Affiches/FamilleAmi.jpeg";
import HappyHour from "../resources/Affiches/HappyHour.jpeg";
import Glace from "../resources/Affiches/Glace.jpeg";
import SalleSport from "../resources/Affiches/SalleSport.jpeg";
import Spa from "../resources/Affiches/Spa.jpeg";
import BeachParty from "../resources/Affiches/BeachParty.jpeg";
import Pilates from "../resources/Affiches/Pilates.jpeg";
import Plongee from "../resources/Affiches/Plongee.jpeg";
import Mediter from "../resources/Affiches/Mediter.jpeg";
import Randonnee from "../resources/Affiches/Randonnee.jpeg";
import FormuleUn from "../resources/Affiches/FormuleUn.jpeg";
import Musee from "../resources/Affiches/Musee.jpeg";

export const AfficheImageMapper = {
    "Automne" : Automne,
    "Perroquet" : Perroquet,
    "Saisons" : Saisons,
    "PatesBolognaise" : PatesBolognaise,
    "Pancake" : Pancake,
    "Chats" : Chats,
    "Vacances" : Vacances,
    "FeteForaine" : FeteForaine,
    "TRex" : TRex,
    "PatesPesto" : PatesPesto,
    "Rio" : Rio,
    "Piment" : Piment,
    "Tigres" : Tigres,
    "Lasagne" : Lasagne,
    "TourEiffel" : TourEiffel,
    "Litchi" : Litchi,
    "Ski" : Ski,
    "Crepes" : Crepes,
    "Fruits" : Fruits,
    "Gouter" : Gouter,
    "Diplodocus" : Diplodocus,
    "Kiwi" : Kiwi,
    "Leopard" : Leopard,
    "Velo" : Velo,
    "Dinosaures" : Dinosaures,
    "Banane" : Banane,
    "SignesAstrologique" : SignesAstrologique,
    "Ete" : Ete,
    "Hibou" : Hibou,
    "Sports" : Sports,
    "Elements" : Elements,
    "Gemeaux" : Gemeaux,
    "Boxe" : Boxe,
    "Colisee" : Colisee,
    "Cannelle" : Cannelle,
    "TirALArc" : TirALArc,
    "Belier" : Belier,
    "Figue" : Figue,
    "Piano" : Piano,
    "Plage" : Plage,
    "Lion" : Lion,
    "MonumentsHistorique" : MonumentsHistorique,
    "Sagittaire" : Sagittaire,
    "Ananas" : Ananas,
    "Hiver" : Hiver,
    "Orange" : Orange,
    "Feu" : Feu,
    "Canides" : Canides,
    "Vierge" : Vierge,
    "Ville" : Ville,
    "Cartes" : Cartes,
    "Tokyo" : Tokyo,
    "Scorpion" : Scorpion,
    "Paris" : Paris,
    "PandaRoux" : PandaRoux,
    "Cancer" : Cancer,
    "Montagne" : Montagne,
    "Londres" : Londres,
    "Epices" : Epices,
    "Athletisme" : Athletisme,
    "Tennis" : Tennis,
    "Eau" : Eau,
    "NewYork" : NewYork,
    "Poisson" : Poisson,
    "Fete" : Fete,
    "Renard" : Renard,
    "Football" : Football,
    "SagradaFamilia" : SagradaFamilia,
    "Churros" : Churros,
    "Verseau" : Verseau,
    "Chien" : Chien,
    "Voley" : Voley,
    "Sydney" : Sydney,
    "Felin" : Felin,
    "Balance" : Balance,
    "Snowboard" : Snowboard,
    "Dormir" : Dormir,
    "Printemps" : Printemps,
    "Fraise" : Fraise,
    "Taureau" : Taureau,
    "Natation" : Natation,
    "LionAstro" : LionAstro,
    "Capricorne" : Capricorne,
    "Loup" : Loup,
    "Boisson" : Boisson,
    "Oiseau" : Oiseau,
    "Triceratops" : Triceratops,
    "Poker" : Poker,
    "Cafe" : Cafe,
    "Curry" : Curry,
    "TourDePise" : TourDePise,
    "Poivre" : Poivre,
    "Peche" : Peche,
    "Caire" : Caire,
    "Escalade" : Escalade,
    "Fennec" : Fennec,
    "InstrumentsDeMusique" : InstrumentsDeMusique,
    "Pates" : Pates,
    "VingtEtUn" : VingtEtUn,
    "PatesCarbonara" : PatesCarbonara,
    "Alcool" : Alcool,
    "Arcade" : Arcade,
    "Avion" : Avion,
    "Egypt" : Egypt,
    "Hawai" : Hawai,
    "Manga" : Manga,
    "Moto" : Moto,
    "Pays" : Pays,
    "Popcorn" : Popcorn,
    "Sel" : Sel,
    "Vehicule" : Vehicule,
    "Voiture" : Voiture,
    "Livre" : Livre,
    "Fin" : Fin,
    "Girafe": Girafe,
    "Cigarette": Cigarette,
    "BoiteDeNuit": BoiteDeNuit,
    "Hamburger": Hamburger,
    "AnimalSauvage": AnimalSauvage,
    "Tatouage": Tatouage,
    "ReseauSocial": ReseauSocial,
    "Surf": Surf,
    "Bijou": Bijou,
    "Skate": Skate,
    "Carnaval": Carnaval,
    "Champagne": Champagne,
    "PoissonSauvage": PoissonSauvage,
    "Festival": Festival,
    "Vegetarien": Vegetarien,
    "Dauphin": Dauphin,
    "LosAngeles": LosAngeles,
    "Cerise": Cerise,
    "Camping": Camping,
    "Piercing": Piercing,
    "Concert": Concert,
    "Chocolat": Chocolat,
    "Comics": Comics,
    "Sucre": Sucre,
    "Armee": Armee,
    "Lumiere": Lumiere,
    "Game": Game,
    "SkinCare": SkinCare,
    "Lune": Lune,
    "Maquillage": Maquillage,
    "Pyramide": Pyramide,
    "Mars": Mars,
    "Pizza": Pizza,
    "Travail": Travail,
    "Sushi": Sushi,
    "Camembert": Camembert,
    "Voyager": Voyager,
    "Myrtille": Myrtille,
    "OliveNoir": OliveNoir,
    "Neige": Neige,
    "Lys": Lys,
    "ChocolatBlanc": ChocolatBlanc,
    "Flute": Flute,
    "Lire": Lire,
    "Japon": Japon,
    "Russie": Russie,
    "Ukraine": Ukraine,
    "Accordeon": Accordeon,
    "Batterie": Batterie,
    "ChocolatNoir": ChocolatNoir,
    "Dubai": Dubai,
    "Ami": Ami,
    "Shopping": Shopping,
    "The": The,
    "Cuisiner": Cuisiner,
    "Koala": Koala,
    "Biere": Biere,
    "Saturne": Saturne,
    "VinBlanc": VinBlanc,
    "Barcelone": Barcelone,
    "Rose": Rose,
    "Barbecue": Barbecue,
    "Paella": Paella,
    "Oeuf": Oeuf,
    "Moule": Moule,
    "Saumon": Saumon,
    "Citation": Citation,
    "VictorHugoQuote": VictorHugoQuote,
    "SteveJobsQuote": SteveJobsQuote,
    "NelsonMandelaQuote": NelsonMandelaQuote,
    "MichelleObamaQuote": MichelleObamaQuote,
    "MarcTwainQuote": MarcTwainQuote,
    "JimRohnQuote": JimRohnQuote,
    "AmeliaEarhartQuote": AmeliaEarhartQuote,
    "Violon": Violon,
    "RoadTrip": RoadTrip,
    "GuitareAcoustique": GuitareAcoustique,
    "GuitareElectrique": GuitareElectrique,
    "Chanter": Chanter,
    "Saxophone": Saxophone,
    "Trompette": Trompette,
    "Danser": Danser,
    "Espace": Espace,
    "France": France,
    "Allemagne": Allemagne,
    "EtatsUnis": EtatsUnis,
    "Chine": Chine,
    "Italie": Italie,
    "Belgique": Belgique,
    "Espagne": Espagne,
    "Etoile": Etoile,
    "Ecrire": Ecrire,
    "Phoque": Phoque,
    "Fondue": Fondue,
    "OliveVerte": OliveVerte,
    "VinRouge": VinRouge,
    "ChocolatAuLait": ChocolatAuLait,
    "FeuDArtifice": FeuxArtifice,
    "Bar": Bar,
    "Fajitas": Fajitas,
    "Bain": Bain,
    "Plante": Plante,
    "SalleGlobal": SalleGlobal,
    "Adidas": Adidas,
    "Disney": Disney,
    "Apple": Apple,
    "Audi": Audi,
    "AmazonPlus": AmazonPlus,
    "CocaCola": CocaCola,
    "Fortnite": Fortnite,
    "Google": Google,
    "Ferrari": Ferrari,
    "Hobbit": Hobbit,
    "HarryPotter": HarryPotter,
    "Instagram": Instagram,
    "JeuMobile": JeuMobile,
    "MacDonalds": MacDonalds,
    "Maternite": Maternite,
    "Monster": Monster,
    "Netflix": Netflix,
    "Nike": Nike,
    "Omega": Omega,
    "Pepsi": Pepsi,
    "PlayStation": PlayStation,
    "Porsche": Porsche,
    "RealiteVirtuel": RealiteVirtuel,
    "RedBull": RedBull,
    "RetroGaming": RetroGaming,
    "Rolex": Rolex,
    "Samsung": Samsung,
    "Spotify": Spotify,
    "Starbuck": Starbuck,
    "StarWars": StarWars,
    "Tesla": Tesla,
    "Twitter": Twitter,
    "XBox": XBox,
    "Youtube": Youtube,
    "Cinema": Cinema,
    "ObserverFaune": ObserverFaune,
    "LeverSoleil": LeverSoleil,
    "Informatique": Informatique,
    "Photographie": Photographie,
    "Peinture": Peinture,
    "Donuts": Donuts,
    "Famille": Famille,
    "PechePoisson": PechePoisson,
    "Entrepreneur": Entrepreneur,
    "Echecs": Echecs,
    "Conference": Conference,
    "StreetFood": StreetFood,
    "Jardinage": Jardinage,
    "EscapeGame": EscapeGame,
    "MurderParty": MurderParty,
    "Bowling": Bowling,
    "Benevolat": Benevolat,
    "BijouxFaitMain": BijouxFaitMain,
    "Canoe": Canoe,
    "Tricot": Tricot,
    "PiqueNique": PiqueNique,
    "Origami": Origami,
    "ParcNaturel": ParcNaturel,
    "AnniversaireSurprise": AnniversaireSurprise,
    "Sculpture": Sculpture,
    "RealiteVirtuelle": RealiteVirtuelle,
    "Dessin": Dessin,
    "BaladeForet": BaladeForet,
    "Flechette": Flechette,
    "Exposition": Exposition,
    "Broderie": Broderie,
    "Scrapbooking": Scrapbooking,
    "SerieFilm": SerieFilm,
    "JardinBotanique": JardinBotanique,
    "Poterie": Poterie,
    "Paintball": Paintball,
    "Chateau": Chateau,
    "Calligraphie": Calligraphie,
    "Patisser": Patisser,
    "ParcAttraction": ParcAttraction,
    "Discotheque": Discotheque,
    "Bateau": Bateau,
    "Musique": Musique,
    "JeuSociete": JeuSociete,
    "JeuAlcool": JeuAlcool,
    "MatchSport": MatchSport,
    "DinerRomantique": DinerRomantique,
    "Cocktail": Cocktail,
    "FamilleAmi": FamilleAmi,
    "HappyHour": HappyHour,
    "Glace": Glace,
    "SalleSport": SalleSport,
    "Spa": Spa,
    "BeachParty": BeachParty,
    "Pilates": Pilates,
    "Plongee": Plongee,
    "Mediter": Mediter,
    "Randonnee": Randonnee,
    "FormuleUn": FormuleUn,
    "Musee": Musee,
}