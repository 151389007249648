
function ConditionsGeneralesUtilisation(props) {

    return (
        <div style={{width: '100vw', backgroundColor: 'white'}}>
            <p style={{margin: 50}}>
                <h1><b>Conditions Générales d'Utilisation</b></h1><br></br><br></br>

                <p>En vigueur au 07/09/2021 
                Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par EQUI et de définir les conditions d’accès et d’utilisation des services par l'utilisateur. 
                Les présentes CGU sont accessibles sur le site à la rubrique «Conditions générales d'utilisation» sous l'onglet "profil"(en bas a droite). 
                Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors de l'inscription sur le site via le Formulaire d’inscription, chaque utilisateur accepte expressément les présentes CGU en cochant la case précédant le texte suivant : « Je reconnais avoir lu et compris les CGU et je les accepte ». 
                En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site. </p>
                <p><b>Article 1 : Coordonnées</b></p>


                <p>L'édition de l'application Talk&Poke est assurée par la Société EQUI au capital de 0 euros, immatriculée au RCS de PARIS sous le numéro 901 795 336, dont le siège social est situé au 223 Avenue de la maréchale 94420 Le plessis trévise<br></br>
                Numéro de téléphone 06 44 19 90 01 <br></br>
                Adresse e-mail : cabaret.hugo@orange.fr <br></br>
                Le Directeur de la publication est : hugo cabaret </p>
                <p><b>ARTICLE 2 : Description</b></p>

                <p>Notre application mobile est un réseau social interactif qui vous permet de connecter avec vos amis et de découvrir de nouvelles affinités passionnantes. Exprimez-vous en réagissant aux affiches de vos amis et mesurez votre compatibilité avec eux grâce à notre algorithme exclusif. Jouez à des jeux amusants pour renforcer vos liens d'amitié et créez des souvenirs inoubliables. Profitez de discussions privées et de chats publics pour échanger des idées et des moments avec d'autres utilisateurs</p>
                <p><b>ARTICLE 3 : Accès a l'application</b></p>


                <p>L'application Talk&Poke permet à l'Utilisateur un accès gratuit aux services suivants : 
                L'application propose les services suivants : 
                Réseau social 
                Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge. 
                L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email. 
                Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qui lui seront communiqués durant son inscription. 
                Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en se rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai raisonnable. 
                Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de Talk&Poke. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis. 
                L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1. </p>

                <p><b>ARTICLE 4 : Collecte des données</b></p>


                <p>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit :  </p>
                · par mail à l'adresse mail cabaret.hugo@orange.fr<br></br>
                · via son espace personnel ;<br></br><br></br>
                <p>Lorsque vous utilisez notre application, nous collectons certaines données pour améliorer votre expérience et vous offrir les fonctionnalités que vous aimez. Nous tenons à vous assurer que la protection de vos données personnelles est une priorité absolue pour nous. Nous ne partageons aucune de vos données avec des tiers. Nous n'utilisons pas vos informations pour des publicités ciblées. En utilisant notre application, vous consentez à ce que vos réactions soient exposées au reste des utilisateurs, que ce soit sous leur forme brute ou transformée en pourcentage de compatibilité. Nous respectons votre choix de confidentialité, et vous avez la possibilité de réduire la visibilité de vos données en passant votre compte en mode 'privé'. Votre vie privée est importante pour nous, et nous nous engageons à la protéger tout en vous offrant une expérience sociale enrichissante. </p>
                
                <p>
                    Pour vous offrir une expérience personnalisée et permettre le bon fonctionnement de notre application, nous collectons certaines informations lors de votre inscription et de votre utilisation. Cela comprend votre adresse e-mail, qui nous permet de vous identifier et de communiquer avec vous, ainsi que votre mot de passe, qui garantit la sécurité de votre compte. Vous choisissez également un pseudonyme pour vous représenter sur notre plateforme.

                    De plus, lorsque vous réagissez aux affiches, vous partagez des informations sensibles, telles que vos goûts et vos préférences. En réagissant, vous nous autorisez automatiquement à stocker et à traiter ces données exclusivement dans le but de générer des pourcentages de compatibilité avec d'autres utilisateurs et de suivre vos réactions par d'autres utilisateurs.

                    Nous utilisons également un système de questions et réponses similaires (comme la localisation, l'orientation, l'âge et les préférences) pour améliorer la précision de nos calculs de compatibilité. Veuillez noter que ces informations sont traitées de manière confidentielle et ne sont partagées qu'avec d'autres utilisateurs dans le but de faciliter des connexions significatives. Votre vie privée est essentielle pour nous, et nous nous engageons à protéger vos données tout en vous offrant une expérience enrichissante.
                </p>

                <p><b>ARTICLE 5 : Durée valable des informations</b></p>


                <p>Nous comprenons l'importance de la protection de vos données personnelles, et nous nous engageons à les conserver de manière responsable. Vos informations personnelles restent actives aussi longtemps que votre compte est actif et tant que vous continuez à utiliser notre application. Toutefois, pour garantir la sécurité de vos données et la gestion efficace de notre plateforme, nous avons mis en place une politique de suppression automatique.

                    Si un compte reste inactif, c'est-à-dire sans aucune connexion à l'application, pendant une période de plus de 24 mois, il sera automatiquement supprimé. Cette mesure vise à protéger vos informations personnelles et à maintenir la qualité de notre communauté en ligne. Si vous choisissez de revenir après une période d'inactivité, vous devrez créer un nouveau compte. Nous vous encourageons à consulter nos conditions d'utilisation pour plus d'informations sur notre politique de conservation des données.</p>
                

                <p><b>ARTICLE 6 : Propriété intellectuelle</b></p>


                <p>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur. 
                La marque Talk&Poke est une marque déposée par Equi. Toute représentation et/ou reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce soit, est totalement prohibée. 
                L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite. 
                Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle. 
                Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.</p>
                <p><b>ARTICLE 7 : Responsabilité</b></p>


                <p>Les sources des informations diffusées sur l'application Talk&Poke sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. 
                Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, l'application Talk&Poke ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans cette aplication. 
                L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le site décline toute responsabilité. 
                L'application Talk&Poke ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site. 
                La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers. </p>
                <p><b>ARTICLE 8 : Liens hypertextes</b></p>


                <p>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira de l'application Talk&Poke Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu. </p>
                <p><b>ARTICLE 9 : Cookies</b></p>


                <p>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation. 
                Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site www.talkandpoke.com. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent. 
                L’information contenue dans les cookies est utilisée pour améliorer le site www.talkandpoke.com. 
                En naviguant sur le site, L’Utilisateur les accepte. 
                L’Utilisateur doit toutefois donner son consentement quant à l’utilisation de certains cookies. 
                A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées. 
                L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation. </p>
                <p><b>ARTICLE 10 : Publication par l’Utilisateur</b></p>


                <p>Le site permet aux membres de publier les contenus suivants : 
                commentaires blog et avis client . 
                Dans ses publications, le membre s’engage à respecter les règles de la Netiquette (règles de bonne conduite de l’internet) et les règles de droit en vigueur. 
                Le site peut exercer une modération sur les publications et se réserve le droit de refuser leur mise en ligne, sans avoir à s’en justifier auprès du membre. 
                Le membre reste titulaire de l’intégralité de ses droits de propriété intellectuelle. Mais en publiant une publication sur le site, il cède à la société éditrice le droit non exclusif et gratuit de représenter, reproduire, adapter, modifier, diffuser et distribuer sa publication, directement ou par un tiers autorisé, dans le monde entier, sur tout support (numérique ou physique), pour la durée de la propriété intellectuelle. Le Membre cède notamment le droit d'utiliser sa publication sur internet et sur les réseaux de téléphonie mobile. 
                La société éditrice s'engage à faire figurer le nom du membre à proximité de chaque utilisation de sa publication. 
                Tout contenu mis en ligne par l'Utilisateur est de sa seule responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout recours en justice engagé par un tiers lésé contre le site sera pris en charge par l'Utilisateur. 
                Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou modifié par le site, sans préavis. </p>

                <p><b>Article 11 : Modération et Signalement</b></p>

                <p>L'utilisation de cette application est soumise aux présentes Conditions Générales d'Utilisation (CGU), qui visent à créer un environnement sûr, respectueux et agréable pour tous nos utilisateurs. Pour atteindre cet objectif, nous avons mis en place des mécanismes de modération et de signalement pour maintenir la qualité du contenu et le comportement des utilisateurs.</p>

                <p>Nous employons des algorithmes de filtrage sophistiqués pour détecter et bloquer automatiquement le contenu jugé inapproprié, y compris mais sans s'y limiter, les discours haineux, le contenu explicite, et le harcèlement. Cependant, il est possible que certains contenus échappent à notre système de filtrage, et c'est pourquoi nous encourageons nos utilisateurs à participer activement à la modération.</p>

                <p>Nous offrons à nos utilisateurs la possibilité de signaler tout contenu qu'ils estiment objectionable, en utilisant notre fonction de signalement intégrée. Cette fonction permet de signaler divers types de contenus inappropriés ou de comportements abusifs.</p>

                <p>Nous mettons à votre disposition un mécanisme qui vous permet de bloquer des utilisateurs avec lesquels vous ne souhaitez pas interagir. En bloquant un utilisateur, vous lui interdisez d'entrer en contact avec vous ou de voir votre contenu.</p>

                <p>Nous nous engageons à examiner et à réagir rapidement aux signalements de contenu objectionable. Notre équipe de modération traitera ces signalements dans un délai de 24 heures. Si le contenu signalé est confirmé comme étant inapproprié, il sera retiré de l'application et les utilisateurs responsables seront soumis à des mesures appropriées, y compris l'éviction.</p>

                <p>Nous vous tiendrons informés des actions prises en réponse à vos signalements. Vous recevrez des notifications ou des mises à jour par e-mail confirmant la résolution de vos signalements.</p>

                <p>Notre processus de modération et de signalement respecte la confidentialité de nos utilisateurs et est en conformité avec les lois et réglementations en vigueur en matière de protection des données et de modération de contenu. Nous vous encourageons à lire attentivement ces CGU et à utiliser les mécanismes de signalement et de blocage lorsque nécessaire pour aider à maintenir une expérience positive au sein de notre communauté. Nous nous réservons le droit de prendre des mesures disciplinaires contre les utilisateurs en violation de ces CGU, y compris l'éviction permanente de l'application. Nous sommes déterminés à créer un environnement inclusif et sûr pour tous nos utilisateurs, et nous apprécions votre collaboration pour atteindre cet objectif.</p>

                <p><b>ARTICLE 12 : Droit applicable et juridiction compétente</b></p>

                <p>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître. </p>
                Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.            </p>
        </div>
      );
}

export default ConditionsGeneralesUtilisation;

