import React from 'react';

function BackArrow(props) {
    
    return (
        <div onClick={() => props.backCommand()} style={{height: 60, width: 60, background: 'orange', borderRadius: 100}}>
            <img style={{width: 60, height: 60}} src='../resources/ChevronLeft.png'></img>
        </div>
      );
}

export default BackArrow;

