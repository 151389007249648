import { useState } from "react";
import AdminLogin from "./AdminLogin";
import DashBoard from "./DashBoard";

function BackOffice(props) {

    let [authenticationToken, setAuthenticationToken] = useState(undefined);

    return (
        <div className="center">
            <AdminLogin setAuthenticationToken={setAuthenticationToken} show={!authenticationToken}/>

            <DashBoard show={authenticationToken}/>
        </div>
      );
}

export default BackOffice;

