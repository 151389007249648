
function DashBoard(props) {

    if(!props.show) return null;

    return (
        <div className="center">
            <p>Admin Dashboard</p>
        </div>
      );
}

export default DashBoard;

